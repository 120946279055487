import React from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import { HookIcon } from "../helper/HookIcon"

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 1.5rem;
  outline: none;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  clip: rect(0 0 0 0);
  height: 1px;
  position: absolute;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: ${props => (props.checked ? colors.ihk_darkblue : "white")};
  transition: all 150ms;
  border: ${props => (!props.valid ? "1px solid red" : "none")};
`

export const CustomCheckbox = ({ className, checked, valid, ...props }) => {
  return (
    <CheckboxContainer {...props}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} valid={valid}>
        <HookIcon />
      </StyledCheckbox>
    </CheckboxContainer>
  )
}
