import React, { useState } from "react"
import formularIcon from "../../assets/images/icon/202009_IHK_Antwortformular_Pfeil.png"
import { CustomCheckbox } from "../customInputs/CustomCheckbox"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import { layoutStyles } from "../../styles/layoutStyles"
import { HookIcon } from "../helper/HookIcon"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const BlueBackgroundContainer = styled.div`
  background-color: ${colors.ihk_lightblue};
`
const InnerConsultingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${layoutStyles.contentWidth};
  min-height: 120px;
  padding: ${layoutStyles.contentPadding};
  margin: 0 auto;
  font-size: 2.25rem;
  font-family: "Asap Condensed", sans-serif;
  color: ${colors.ihk_darkblue};
  cursor: pointer;
`
const InnerConsultingContainerWithMargin = styled(InnerConsultingContainer)`
  hyphens: auto;
  @media only screen and (max-width: 650px) {
    margin: 1rem auto;
    font-size: 2rem;
  }
`

const StMeasureForm = styled.div`
  max-height: ${props => (props.isFormOpen ? "2000px" : "0")};
  max-width: ${layoutStyles.contentWidth};
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
  margin: 0 auto;
`
const FormInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  font-family: "Asap Condensed", sans-serif;
`
const FormInputContainerWithoutMarginTop = styled(FormInputContainer)`
  margin-top: 0;
`
const FormInputContainerWithoutMarginBottom = styled(FormInputContainer)`
  margin-bottom: 0;
`

const StFormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.35rem;
  border: ${props => (!props.valid ? "1px solid red" : "none")};
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
`
const InputSuffix = styled.span`
  position: absolute;
  left: 2px;
  top: 18px;
  color: ${colors.ihk_darkgrey};
`
const StFormInputLessMarginTop = styled(StFormInput)`
  margin-top: 0.2rem;
`
const StFormInputRow = styled(StFormInput)`
  width: 100% !important;
  margin-right: 1rem;
`

const StRowContainer = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`
const StLeftColumnContainer = styled.div`
  width: 100%;
`

const FormInputMeasure = styled.textarea`
  font-size: 1rem;
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.25rem;
  border: none;
  height: 50px;
  resize: none;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
`

const StFormButton = styled.button`
  background-color: ${colors.ihk_darkblue};
  color: white;
  font-weight: bold;
  width: fit-content;
  padding: 0.75rem 3rem;
  margin: 2rem 0;
  border: none;
  outline: none;
  cursor: pointer;
`
const StFormButtonDisabled = styled(StFormButton)`
  display: flex;
  align-items: center;
  cursor: auto;
  background-color: ${colors.ihk_lightblue};
  border: 1px solid ${colors.ihk_darkblue};
  color: ${colors.ihk_darkblue};
`

const StHookIconBlue = styled(HookIcon)`
  stroke: ${colors.ihk_darkblue};
  margin-right: 1rem;
`

const PrivacyContainer = styled.label`
  display: flex;
  width: 100%;
`

const DataPrivacyText = styled.p`
  margin-top: 0 !important;
  margin-right: 2rem;
  @media only screen and (max-width: 799px) {
    margin-right: 0;
  }
`
const FormularIcon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 1rem;
`

const HorizontalAlignedInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  input {
    width: 100%;
  }

  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`

const RightContainerInHorizontalAlignedInputs = styled.div`
  margin-left: 5rem;
  width: 100%;

  @media only screen and (max-width: 799px) {
    margin-left: 0 !important;
  }
`
const LeftContainerInHorizontalAlignedInputs = styled.div`
  width: 100%;
`

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 102.5%;
  margin-top: 0.5rem;
`
const ContactRowContainerTextInputs = styled(ContactRow)`
  @media only screen and (max-width: 799px) {
    flex-direction: column;
    width: 100%;
  }
`
const ContactRowContainer = styled.div`
  margin-top: 1rem;
  font-family: "Asap Condensed", sans-serif;
`

const InfoSpan = styled.span`
  margin-left: 0.5rem;
  font-size: smaller;
  color: ${colors.ihk_darkgrey};
`
const StSub = styled.sub`
  font-size: 0.75rem;
`

const BottomContainer = styled.div`
  display: flex;
  width: 75%;
  align-items: center;
  margin-bottom: 2rem;

  @media only screen and (max-width: 799px) {
    flex-wrap: wrap-reverse;
    width: 100%;
  }
`

const GenderLabel = styled.span`
  cursor: pointer;
  padding: 0 1rem;
  font-family: "Source Sans Pro", sans-serif;
  color: ${colors.ihk_darkgrey};
`
const StForm = styled.form`
  max-width: ${layoutStyles.contentWidth};
  margin-right: 1.2rem;
`

export const MeasureForm = ({ isFormOpen, setIsFormOpen }) => {
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [privacyCheckedValid, setPrivacyCheckedValid] = useState(true)

  const [companyName, setCompanyName] = useState("")
  const [companyNameValid, setCompanyNameValid] = useState(true)

  const [measure, setMeasure] = useState("")

  const [investAmount, setInvestAmount] = useState("")

  const [paybackPeriod, setPaybackPeriod] = useState("")
  const [paybackPeriodValid] = useState(true)

  const [energysavingsPerYear, setEnergysavingsPerYear] = useState("")
  const [energysavingsPerYearValid] = useState(true)

  const [coTwoSavingPerYear, setCoTwoSavingPerYear] = useState("")

  const [maleChecked, setMaleChecked] = useState(false)
  const [femaleChecked, setFemaleChecked] = useState(false)
  const [diversChecked, setDiversChecked] = useState(false)
  const [genderValid, setGenderValid] = useState(true)

  const [name, setName] = useState("")
  const [nameValid, setNameValid] = useState(true)

  const [mail, setMail] = useState("")
  const [mailValid, setMailValid] = useState(true)
  const [phone, setPhone] = useState("")
  const [phoneValid, setPhoneValid] = useState(true)
  const [formHasBeenSent, setFormHasBeenSent] = useState(false)

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
    return formData
  }

  const sendEtrackerFormularSubmissionEvent = event => {
    if (
      typeof window !== "undefined" &&
      window &&
      window._etracker &&
      window._etracker.sendEvent &&
      window.et_UserDefinedEvent
    ) {
      const _etracker = window._etracker
      _etracker.sendEvent(
        new window.et_UserDefinedEvent(
          "Know-How-Formular-Gesendet",
          "Formulare",
          "Absenden"
        )
      )
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    let companyNameValid = true
    let nameValid = true
    let phoneValid = true
    let mailValid = true
    let privacyValid = true
    let genderValid = true

    if (companyName.length < 1) {
      companyNameValid = false
      setCompanyNameValid(false)
    } else {
      setCompanyNameValid(true)
    }

    if (name.length < 1) {
      nameValid = false
      setNameValid(false)
    } else {
      setNameValid(true)
    }

    if (!maleChecked && !femaleChecked && !diversChecked) {
      genderValid = false
      setGenderValid(false)
    } else {
      setGenderValid(true)
    }

    if (mail.length < 3) {
      setMailValid(false)
      mailValid = false
    } else {
      setMailValid(true)
    }
    if (phone.length < 3) {
      setPhoneValid(false)
      phoneValid = false
    } else {
      setPhoneValid(true)
    }

    if (!privacyChecked) {
      setPrivacyCheckedValid(false)
      privacyValid = false
    } else {
      setPrivacyCheckedValid(true)
    }

    if (
      !companyNameValid ||
      !mailValid ||
      !privacyValid ||
      !nameValid ||
      !phoneValid ||
      !genderValid
    ) {
      return
    }

    if (
      companyNameValid &&
      mailValid &&
      privacyValid &&
      nameValid &&
      phoneValid &&
      genderValid
    ) {
      const form = event.target

      fetch("/", {
        method: "POST",
        // headers: { "Content-Type": "multipart/form-data" },
        body: encode({
          "form-name": form.getAttribute("name"),
          Unternehmensname: companyName,
          "Klima Und Energiesparmasnahme": measure,
          Investitionssumme: investAmount,
          Amortisationszeit: paybackPeriod,
          "Energieeinsparung pro Jahr in kWh": energysavingsPerYear,
          "C02 Reduktion pro Jahr": coTwoSavingPerYear,
          Anrede: maleChecked ? "Herr" : femaleChecked ? "Frau" : "Divers",
          "Vor und Nachname": name,
          "e-mail": mail,
          Telefonnummer: phone,
        }),
      })
        .then(() => {
          setCompanyName("")
          setMeasure("")
          setInvestAmount(0)
          setPaybackPeriod(0)
          setEnergysavingsPerYear(0)
          setCoTwoSavingPerYear(0)
          setMaleChecked(false)
          setFemaleChecked(false)
          setName("")
          setNameValid(true)
          setPhoneValid(true)

          setMail("")
          setPhone("")
          setPrivacyChecked(false)
          setFormHasBeenSent(true)
          sendEtrackerFormularSubmissionEvent(event)
        })
        .catch(error =>
          alert(
            "Leider ist beim Senden Ihrer Anfrage ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns bitte."
          )
        )
    }
  }

  return (
    <Container id={"knowhow-teilen"}>
      <BlueBackgroundContainer>
        <InnerConsultingContainerWithMargin
          onClick={() => {
            setIsFormOpen(!isFormOpen)
          }}
        >
          <FormularIcon src={formularIcon} alt={"Formular öffnen/schließen"} />
          Ihr Klima oder Energieeffizienz&shy;projekt auf unserer Seite?
        </InnerConsultingContainerWithMargin>
        <StMeasureForm isFormOpen={isFormOpen}>
          <StForm
            onSubmit={handleFormSubmit}
            name={"Klima- und Energieeffizienz- maßnahme"}
            method={"POST"}
            data-netlify="true"
          >
            <input
              type="hidden"
              name="form-name"
              value="Klima- und Energieeffizienz- maßnahme"
            />
            <StRowContainer>
              <StLeftColumnContainer>
                <FormInputContainer>
                  Unternehmensname
                  <StFormInput
                    onChange={e => {
                      setCompanyName(e.target.value)
                    }}
                    value={companyName}
                    valid={companyNameValid}
                    type="text"
                    name="Unternehmensname"
                    aria-label={"Unternehmensname"}
                    id={"Unternehmensname"}
                    placeholder={"Pflichtangabe"}
                  />
                </FormInputContainer>
              </StLeftColumnContainer>
            </StRowContainer>
            <FormInputContainer>
              Kurzbeschreibung der Klima- und Energiesparmaßnahme
              <FormInputMeasure
                onChange={e => {
                  setMeasure(e.target.value)
                }}
                value={measure}
                valid={true}
                type="text"
                name="Klima Und Energiesparmasnahme"
                aria-label={"Klima- und Energiesparmaßnahme"}
                id={"measure"}
                placeholder={""}
              />
            </FormInputContainer>

            <HorizontalAlignedInputsContainer>
              <LeftContainerInHorizontalAlignedInputs>
                <FormInputContainerWithoutMarginTop>
                  Investitionssumme&nbsp;in&nbsp;Euro
                  <div style={{ position: "relative", zIndex: 1 }}>
                    <StFormInput
                      onChange={e => {
                        setInvestAmount(e.target.value)
                      }}
                      value={investAmount}
                      valid={true}
                      type="number"
                      name="Investitionssumme"
                      aria-label={"Investitionssumme"}
                      id={"investAmount"}
                      step={1000}
                      placeholder={""}
                    />
                    <InputSuffix>€</InputSuffix>
                  </div>
                </FormInputContainerWithoutMarginTop>
                <FormInputContainer>
                  Amortisationszeit&nbsp;in&nbsp;Jahren
                  <StFormInput
                    onChange={e => {
                      setPaybackPeriod(e.target.value)
                    }}
                    value={paybackPeriod}
                    valid={paybackPeriodValid}
                    type="number"
                    name="Amortisationszeit"
                    aria-label={"Amortisationszeit"}
                    id={"paybackPeriod"}
                    placeholder={""}
                  />
                </FormInputContainer>
              </LeftContainerInHorizontalAlignedInputs>

              <RightContainerInHorizontalAlignedInputs>
                <FormInputContainerWithoutMarginTop>
                  Energieeinsparung&nbsp;pro&nbsp;Jahr&nbsp;in&nbsp;kWh
                  <StFormInput
                    onChange={e => {
                      setEnergysavingsPerYear(e.target.value)
                    }}
                    value={energysavingsPerYear}
                    valid={energysavingsPerYearValid}
                    type="number"
                    aria-label={"Energieeinsparung pro Jahr in kWh"}
                    name="Energieeinsparung pro Jahr in kWh"
                    id={"energysavingsPerYear"}
                    placeholder={""}
                  />
                </FormInputContainerWithoutMarginTop>
                <FormInputContainerWithoutMarginBottom>
                  <span>
                    C0<StSub>2</StSub> Reduktion pro Jahr&nbsp;in&nbsp;Tonnen
                  </span>
                  <StFormInputLessMarginTop
                    onChange={e => {
                      setCoTwoSavingPerYear(e.target.value)
                    }}
                    value={coTwoSavingPerYear}
                    valid={true}
                    type="number"
                    name="C02 Reduktion pro Jahr"
                    aria-label={"C02 Reduktion pro Jahr"}
                    id={"coTwoSavingPerYear"}
                    placeholder={""}
                  />
                </FormInputContainerWithoutMarginBottom>
              </RightContainerInHorizontalAlignedInputs>
            </HorizontalAlignedInputsContainer>

            <ContactRowContainer>
              Kontakt für Rückfragen der Redaktion{" "}
              <InfoSpan>Pflichtangabe</InfoSpan>
              <ContactRow>
                <label>
                  <CustomCheckbox
                    id={"maleGender"}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setFemaleChecked(false)
                        setDiversChecked(false)
                        setMaleChecked(!maleChecked)
                      }
                    }}
                    tabIndex={0}
                    valid={genderValid}
                    onChange={e => {
                      setFemaleChecked(false)
                      setDiversChecked(false)
                      setMaleChecked(e.target.checked)
                    }}
                    checked={maleChecked}
                    style={{ paddingRight: "0" }}
                  />
                  <GenderLabel>Herr</GenderLabel>
                </label>
                <label>
                  <CustomCheckbox
                    id={"femaleGender"}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setMaleChecked(false)
                        setDiversChecked(false)
                        setFemaleChecked(!femaleChecked)
                      }
                    }}
                    valid={genderValid}
                    onChange={e => {
                      setMaleChecked(false)
                      setDiversChecked(false)
                      setFemaleChecked(e.target.checked)
                    }}
                    checked={femaleChecked}
                    style={{ paddingRight: "0" }}
                  />
                  <GenderLabel>Frau</GenderLabel>
                </label>
                <label>
                  <CustomCheckbox
                    id={"diversGender"}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setMaleChecked(false)
                        setFemaleChecked(false)
                        setDiversChecked(!diversChecked)
                      }
                    }}
                    valid={genderValid}
                    onChange={e => {
                      setMaleChecked(false)
                      setFemaleChecked(false)
                      setDiversChecked(e.target.checked)
                    }}
                    checked={diversChecked}
                    style={{ paddingRight: "0" }}
                  />
                  <GenderLabel>Divers</GenderLabel>
                </label>
              </ContactRow>
              <ContactRowContainerTextInputs>
                <StFormInputRow
                  onChange={e => {
                    setName(e.target.value)
                  }}
                  value={name}
                  valid={nameValid}
                  type="text"
                  aria-label={"Vor und Nachname"}
                  name="Vor und Nachname"
                  id={"name"}
                  placeholder={"Vor-, Nachname"}
                />

                <StFormInputRow
                  onChange={e => {
                    setPhone(e.target.value)
                  }}
                  value={phone}
                  valid={phoneValid}
                  type="tel"
                  aria-label={"Telefonnummer"}
                  name="Telefonnummer"
                  id={"phone"}
                  placeholder={"Telefonnummer"}
                />
                <StFormInputRow
                  onChange={e => {
                    setMail(e.target.value)
                  }}
                  style={{ marginRight: 0 }}
                  value={mail}
                  valid={mailValid}
                  type="email"
                  name="e-mail"
                  aria-label={"email"}
                  id={"mail"}
                  placeholder={"E-Mail"}
                />
              </ContactRowContainerTextInputs>
            </ContactRowContainer>

            {!formHasBeenSent ? (
              <StFormButton type={"submit"} tabIndex={0}>
                ANFRAGE ABSENDEN
              </StFormButton>
            ) : (
              <StFormButtonDisabled disabled>
                <StHookIconBlue />
                ANFRAGE WURDE GESENDET
              </StFormButtonDisabled>
            )}

            <BottomContainer>
              <PrivacyContainer>
                <CustomCheckbox
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      setPrivacyChecked(!privacyChecked)
                      setPrivacyCheckedValid(true)
                    }
                  }}
                  tabIndex={0}
                  checked={privacyChecked}
                  onChange={e => {
                    setPrivacyChecked(e.target.checked)
                    setPrivacyCheckedValid(true)
                  }}
                  valid={privacyCheckedValid}
                />
                <DataPrivacyText onClick={e => e.preventDefault()}>
                  Mit dem Absenden Ihrer Daten erklären Sie sich damit
                  einverstanden, dass wir Ihre Daten ausschließlich zu
                  Kontaktzwecken nutzen und speichern. Die Daten können auf
                  Wunsch jederzeit gelöscht, widerrufen, eingesehen,
                  transferiert und berichtigt werden. Nähere Informationen dazu
                  entnehmen Sie bitte unserer Datenschutzerklärung.
                </DataPrivacyText>
              </PrivacyContainer>
            </BottomContainer>
          </StForm>
        </StMeasureForm>
      </BlueBackgroundContainer>
    </Container>
  )
}
