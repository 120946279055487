import styled from "styled-components"
import React from "react"

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  width: 25px;
  height: 25px;
`

export const HookIcon = props => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <polyline points="20 6 9 17 4 12" />
    </Icon>
  )
}
